import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

import { Header, Lists } from "../../components";
import { useAuth } from "../../context/AuthContext";
import { colors } from "../../utils";
import { riwayatPresensiUrl } from "../../helpers/ApiPresensi";
import { decryptLocalStorage } from "../../helpers/SecureStorage";

const RiwayatPresensi = () => {
  const { logout } = useAuth();
  const [tglawal, settglawal] = useState(new Date());
  const [tglakhir, settglakhir] = useState(new Date());
  const [riwayat, setriwayat] = useState([]);

  // const api_key = localStorage.getItem("api_key");
  // const url = "https://api-presensi.ponpeszamzam.my.id/laporan/presensi";

  const token = decryptLocalStorage(process.env.REACT_APP_LOCAL_STORAGE_TOKEN);

  const getRiwayat = async (tglawal, tglakhir) => {
    const body = {
      tanggal_awal: moment(tglawal).format("yyyy-MM-DD"),
      tanggal_akhir: moment(tglakhir).format("yyyy-MM-DD"),
      jenis_presensi: "REGULER",
    };

    try {
      const response = await fetch(riwayatPresensiUrl, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        logout();
        // throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      // console.log(body);

      setriwayat(data.data);
    } catch (error) {
      // console.error("Error during login:", error);
    }
  };

  const onfilter = (date, type) => {
    if (type === "awal") {
      settglawal(date);
      getRiwayat(date, tglakhir);
    } else {
      settglakhir(date);
      getRiwayat(tglawal, date);
    }
  };

  useEffect(() => {
    getRiwayat(tglawal, tglakhir);
  }, []);

  return (
    <div className="flex flex-col min-h-screen overflow-x-hidden">
      <Helmet>
        <title>Riwayat Presensi</title>
      </Helmet>

      <div className="flex-grow" style={{ backgroundColor: colors.whitegrey }}>
        <div
          className="w-full py-8 items-start"
          style={{
            height: 130,
            backgroundColor: colors.blue,
          }}
        ></div>

        <div className="lg:mx-8" style={{ marginTop: -120 }}>
          <div className="p-4" style={{ borderRadius: 12 }}>
            <Header to="/home" title="Riwayat Presensi" />
            <div className="flex flex-col lg:flex-row items-center sm:justify-center lg:justify-between p-4 mt-4 rounded-md bg-white">
              <div>
                <label
                  htmlFor="datepicker"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Tanggal Awal
                </label>

                <DatePicker
                  className="datepicker-custom w-full px-4 py-2 border-2 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  selected={tglawal}
                  onChange={(date) => onfilter(date, "awal")}
                  dateFormat="dd/MM/yyyy"
                />
              </div>

              <div className="text-black text-md">s.d</div>

              <div>
                <label
                  htmlFor="datepicker"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Tanggal Akhir
                </label>

                <DatePicker
                  className="w-full px-4 py-2 border-2 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  selected={tglakhir}
                  onChange={(date) => onfilter(date, "akhir")}
                  dateFormat="dd/MM/yyyy"
                />
              </div>
            </div>

            {riwayat ? (
              riwayat?.map((item, index) => (
                <Lists
                  riwayatpresensi
                  key={index}
                  text={item.tgl}
                  text2={
                    item.jam_masuk == null
                      ? "-- : --"
                      : moment(item.jam_masuk, "HH:mm:ss").format("HH:mm")
                  }
                  text3={item.status_berangkat}
                  text4={
                    item.jam_pulang == null
                      ? "-- : --"
                      : moment(item.jam_pulang, "HH:mm:ss").format("HH:mm")
                  }
                  text5={item.status_pulang}
                />
              ))
            ) : (
              <div className="flex justify-center items-center h-full">
                <p className="text-black text-base mt-4">
                  Data tidak ditemukan
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RiwayatPresensi;

import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import { colors } from "../../utils";
import { ItemJadwal, Header } from "../../components";
import { useAuth } from "../../context/AuthContext";
import { jadwalUrl } from "../../helpers/ApiPresensi";
import { decryptLocalStorage } from "../../helpers/SecureStorage";

const Jadwal = () => {
  const { logout } = useAuth();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // const api_key = localStorage.getItem("api_key");
  // const url = "https://api-presensi.ponpeszamzam.my.id/jadwal";

  const token = decryptLocalStorage(process.env.REACT_APP_LOCAL_STORAGE_TOKEN);

  const getJadwal = async () => {
    try {
      const response = await fetch(jadwalUrl, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
      });

      if (!response.ok) {
        logout();
        // throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if ("data" in data) {
        setData(data.data);
      } else {
        setData([]);
      }
    } catch (error) {
      // console.error("Error during login:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getJadwal();
  }, []);

  return (
    <div className="flex flex-col min-h-screen overflow-x-hidden">
      <Helmet>
        <title>Jadwal Presensi</title>
      </Helmet>

      <div className="flex-grow" style={{ backgroundColor: colors.whitegrey }}>
        <div
          className="w-full py-8 items-start"
          style={{
            height: 130,
            backgroundColor: colors.blue,
          }}
        ></div>

        <div className="lg:mx-8" style={{ marginTop: -120 }}>
          <div className="p-4" style={{ borderRadius: 12 }}>
            <Header to="/home" title="Jadwal Presensi" />

            {isLoading ? (
              <div className="flex justify-center items-center h-full">
                <p className="text-white text-base">Loading...</p>
              </div>
            ) : (
              data.map((item) => (
                <ItemJadwal
                  hari={item.hari}
                  tgl={item.tgl}
                  jadwal_masuk={item.jadwal_masuk}
                  jadwal_pulang={item.jadwal_pulang}
                ></ItemJadwal>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Jadwal;

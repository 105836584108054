import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";

import { ImageBg, Logo } from "../../assets";
import { colors } from "../../utils";
import { useAuth } from "../../context/AuthContext";

const Login = () => {
  const { login, isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await login(username, password, "123456");
    } catch (error) {
      setLoginError("Login tidak berhasil");
      setUsername("");
      setPassword("");
      setTimeout(() => {
        setLoginError(null);
      }, 5000);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/home");
    }
  }, [isAuthenticated, navigate]);

  return (
    <div
      className="flex min-h-screen items-center justify-center px-6 py-12 lg:px-8"
      style={{
        backgroundImage: `url(${ImageBg})`,
        backgroundSize: "cover",
      }}
    >
      <Helmet>
        <title>Login</title>
      </Helmet>

      <div
        style={{ backgroundColor: colors.white, padding: 36, borderRadius: 8 }}
        className="shadow-md"
      >
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img className="mx-auto h-10 w-auto" src={Logo} alt="Zamzam" />
          <h2 className="mt-4 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Presensi Zamzam
          </h2>
        </div>

        {loginError && (
          <div
            className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded flex items-center justify-between mt-4"
            role="alert"
          >
            <span className="flex-grow">{loginError}</span>
            <button
              className="ml-4 px-2 py-1 mt-1 mr-1 text-red-700 hover:text-red-800 focus:outline-none"
              onClick={() => setLoginError(null)}
            >
              <svg
                className="h-6 w-6 fill-current"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 1a9 9 0 100 18 9 9 0 000-18zm4.95 13.95a.75.75 0 11-1.06 1.06L10 11.06l-3.89 3.89a.75.75 0 11-1.06-1.06L8.94 10 5.05 6.11a.75.75 0 111.06-1.06L10 8.94l3.89-3.89a.75.75 0 111.06 1.06L11.06 10l3.89 3.89z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        )}

        <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <label
                for="username"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Username
              </label>
              <div className="mt-2">
                <input
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  id="username"
                  name="username"
                  type="text"
                  placeholder="Masukkan NIS"
                  autocomplete="text"
                  className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  for="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Password
                </label>
              </div>
              <div className="mt-2">
                <input
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  id="password"
                  name="password"
                  type="password"
                  placeholder="Masukkan Password"
                  autocomplete="current-password"
                  className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="mt-8">
              <button
                type="submit"
                className="flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                style={{ backgroundColor: colors.blue, color: colors.white }}
              >
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import "moment/locale/id";

import moment from "moment";
import { Helmet } from "react-helmet";
import { ShimmerTitle } from "react-shimmer-effects";

import {
  IconPresensiMasukPutih,
  IconPresensiPulangPutih,
  ImageBg,
  ImageUser
} from "../../assets";
import { Buttons, Footer } from "../../components";
import { useAuth } from "../../context/AuthContext";
import { biodataUrl, dashboardUrl } from "../../helpers/ApiPresensi";
import { decryptLocalStorage } from "../../helpers/SecureStorage";
import { colors } from "../../utils";

const Home = () => {
  const [date] = useState(new Date());
  const [biodata, setbiodata] = useState([]);
  const [aktivitasList, setAktivitasList] = useState([]);

  const navigate = useNavigate();
  const { logout } = useAuth();
  const [ringkasan, setringkasan] = useState([]);
  const [messagejadwal, setmessagejadwal] = useState("");
  const [jumlahShift, setJumlahShift] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [bulanRingkasan, setBulanRingkasan] = useState("");
  const [shift, setshift] = useState([])

  // var waktusekarang = moment(date).format("HH:mm:ss")
  // const url = "https://api-presensi.ponpeszamzam.my.id/dashboard";
  // const api_key = localStorage.getItem("api_key");

  const token = decryptLocalStorage(process.env.REACT_APP_LOCAL_STORAGE_TOKEN);

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });

  const getdashboard = async () => {
    // const jenispegawai = localStorage.getItem("jenispegawai");

    // setjenispegawai(jenispegawai);

    // const data = localStorage.getItem("shift");

    // setshift(data);

    // if (data) {
    //   const cleanedJsonString = data.replace(/\\/g, "");
    //   const parsedData = JSON.parse(cleanedJsonString);
    //   setshift(parsedData);
    // }

    try {
      setIsLoading(true);

      const response = await fetch(dashboardUrl, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status !== 200) {
        const data = await response.json();

        // console.log(data);

        if (response.status === 401) {
          logout();
        }

        Toast.fire({
          icon: "error",
          title: data.message,
        });
        return;
      }

      const data = await response.json();

      // setbiodata(data.data.pegawai);
      // setshift(data.data.shift);
      // setjenispegawai(data.data.pegawai.jenis_pegawai);
      // setringkasan(data.data.ringkasan);
      // setaktifitas(data.data.aktivitas);
      // setmessagejadwal(data.data.message);

      if (data.data.aktivitas) {
        setAktivitasList(data.data.aktivitas);
      }

      if (data.data.shift) {
        setJumlahShift(data.data.shift.length);
        setshift(data.data.shift)
      }

      setringkasan(data.data.ringkasan);
      setmessagejadwal(data.data.message);
    } catch (error) {
      // console.error("Error during login:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getbio = async () => {
    try {
      setIsLoading(true);

      // const url = "https://api-presensi.ponpeszamzam.my.id/biodata";
      const response = await fetch(biodataUrl, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status !== 200) {
        const data = await response.json();

        Toast.fire({
          icon: "error",
          title: data.message,
        });
        return;
      }

      const data = await response.json();
      setbiodata(data.data);
    } catch (error) {
      // console.error("Error during login:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const gotopresensi = (tipe) => {
    const data = {
      state: tipe,
    };

    navigate("/presensi", data);
  };

  const gotouploaddinas = () => {
    const data = {
      shift: shift
    }
    navigate("/upload-dinas", { state: data });
  }

  useEffect(() => {
    getbio();
    getdashboard();

    // console.log(ringkasan);
  }, []);

  useEffect(() => {
    if (ringkasan?.bulan && ringkasan?.tahun) {
      const sekarang = moment(
        `${ringkasan.bulan}-${ringkasan.tahun}`,
        "MM-YYYY"
      );
      const formattedSekarang = sekarang.format("MMMM YYYY");
      setBulanRingkasan(formattedSekarang);
    }
  }, [ringkasan]);

  return (
    <div className="flex flex-col min-h-screen overflow-x-hidden">
      <Helmet>
        <title>Home</title>
      </Helmet>

      <div className="flex-grow" style={{ backgroundColor: colors.whitegrey }}>
        <div
          className="py-8 items-start"
          style={{
            backgroundImage: `url(${ImageBg})`,
            backgroundSize: "cover",
            height: 200,
          }}
        >
          {/* Informasi nama dan profile */}

          <div className="flex row-auto items-center justify-between flex-grow mx-8">
            <div>
              <h2 className="text-left text-2xl font-semibold tracking-tight text-white">
                {isLoading ? <p>Loading ...</p> : biodata?.nama_pegawai}

                {/* {biodata?.nama_pegawai} */}
              </h2>
              <h5 className="text-left font-semibold tracking-tight text-white">
                {isLoading ? <p>Loading ...</p> : biodata?.jenis_pegawai}

                {/* {biodata?.jenis_pegawai} */}
              </h5>
            </div>

            <div className="">
              {isLoading ? (
                <p className="text-white">Loading ...</p>
              ) : (
                <img
                  alt="candice"
                  src={biodata?.foto === false ? ImageUser : biodata?.foto}
                  className="relative inline-block !rounded-full object-cover object-center w-16"
                />
              )}
            </div>
          </div>

          {/* Informasi nama dan profile */}
        </div>

        <div
          className="flex flex-col items-center justify-center"
          style={{ marginTop: -56 }}
        >
          <div className="w-full px-4">
            {/* Tombol presensi masuk dan pulang */}

            {isLoading ? (
              <div
                className="p-4 bg-white mt-4 shadow-md"
                style={{ borderRadius: 12, borderColor: colors.blue }}
              >
                <ShimmerTitle line={1} gap={10} variant="primary" />

                <ShimmerTitle line={1} gap={10} variant="primary" />
              </div>
            ) : (
              <div
                className="p-4 bg-white shadow-md"
                style={{ borderRadius: 12 }}
              >
                <div className="flex row-auto items-center justify-between">
                  <h5 className="text-left font-semibold tracking-tight text-gray-500 text-sm">
                    {moment(date).format("DD-MM-yyyy")}
                  </h5>
                  <h5 className="text-left font-semibold tracking-tight text-gray-500 text-sm">
                    {jumlahShift} Jadwal Shift
                  </h5>
                </div>
                <div className="flex row-auto items-center justify-between mt-4 space-x-4">
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 mr-6"
                    style={{
                      backgroundColor: colors.blue,
                      color: colors.white,
                    }}
                    onClick={() => gotopresensi("masuk")}
                  >
                    Masuk
                  </button>
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ml-6"
                    style={{
                      backgroundColor: colors.blue,
                      color: colors.white,
                    }}
                    onClick={() => gotopresensi("pulang")}
                  >
                    Pulang
                  </button>
                </div>
                <h5 className="mt-4 text-center text-gray-500 font-semibold tracking-tight">
                  {messagejadwal}
                </h5>
              </div>
            )}

            {/* Tombol presensi masuk dan pulang */}

            {/* Ringkasan */}

            <div className="flex row-auto items-center justify-between mt-4">
              <h5 className="text-center text-gray-500 font-extrabold tracking-tight">
                Ringkasan
              </h5>
              <h5 className="text-center text-gray-500 font-semibold tracking-tight">
                {isLoading ? "Loading" : bulanRingkasan}
              </h5>
            </div>

            {isLoading ? (
              <div
                className="p-4 bg-white mt-4 shadow-md"
                style={{ borderRadius: 12, borderColor: colors.blue }}
              >
                <ShimmerTitle line={1} gap={10} variant="primary" />

                <ShimmerTitle line={1} gap={10} variant="primary" />
              </div>
            ) : (
              <div className="flex sm:flex-nowrap shadow-md flex-wrap sm:gap-4 gap-2 justify-center bg-white rounded-lg py-4 mt-4 sm:px-4">
                <div
                  style={{ backgroundColor: colors.greenlight }}
                  className="flex flex-col items-center justify-center rounded-lg shadow-md bg-white sm:w-full sm:h-full sm:py-2 w-11 h-11"
                >
                  <div className="text-sm">{ringkasan?.jumlah_tepat}</div>
                  <div className="text-xs font-normal">Tepat</div>
                </div>

                <div
                  style={{ backgroundColor: colors.redlight }}
                  className="flex flex-col items-center justify-center rounded-lg shadow-md bg-white sm:w-full sm:h-full sm:py-2 w-11 h-11"
                >
                  <div className="text-sm">{ringkasan?.jumlah_telat}</div>
                  <div className="text-xs font-normal">Telat</div>
                </div>

                <div
                  style={{ backgroundColor: colors.yellowlight }}
                  className="flex flex-col items-center justify-center rounded-lg shadow-md bg-white sm:w-full sm:h-full sm:py-2 w-11 h-11"
                >
                  <div className="text-sm">{ringkasan?.jumlah_izin}</div>
                  <div className="text-xs font-normal">Izin</div>
                </div>

                <div
                  style={{ backgroundColor: colors.blue2 }}
                  className="flex flex-col items-center justify-center rounded-lg shadow-md bg-white sm:w-full sm:h-full sm:py-2 w-11 h-11"
                >
                  <div className="text-sm">{ringkasan?.jumlah_cuti}</div>
                  <div className="text-xs font-normal">Cuti</div>
                </div>

                <div
                  style={{ backgroundColor: colors.greenlight }}
                  className="flex flex-col items-center justify-center rounded-lg shadow-md bg-white sm:w-full sm:h-full sm:py-2 w-11 h-11"
                >
                  <div className="text-sm">{ringkasan?.jumlah_dl}</div>
                  <div className="text-xs font-normal">Dinas</div>
                </div>

                <div
                  style={{ backgroundColor: colors.redlight }}
                  className="flex flex-col items-center justify-center rounded-lg shadow-md bg-white sm:w-full sm:h-full sm:py-2 w-11 h-11"
                >
                  <div className="text-sm">
                    {ringkasan?.jumlah_tanpa_keterangan}
                  </div>
                  <div className="text-xs font-normal">Alpa</div>
                </div>
              </div>
            )}

            {/* Ringkasan */}

            <div className="mt-4">
              <h5 className="text-left text-gray-500 font-extrabold tracking-tight">
                Menu
              </h5>
            </div>

            <div
              className="p-4 mt-4 bg-white shadow-md"
              style={{ borderRadius: 12 }}
            >
              <div className="flex row-auto items-start justify-between">
                <Link to="/jadwal">
                  <Buttons menu icontype="presensi" text="Jadwal" />
                </Link>

                <Link to="/riwayat-presensi">
                  <Buttons menu icontype="riwayat" text="Riwayat Presensi" />
                </Link>

                <Link to="/rekap-presensi">
                  <Buttons menu icontype="reporting" text="Rekap Presensi" />
                </Link>

                <Link to="/izin">
                  <Buttons menu icontype="izin" text="Izin" />
                </Link>
              </div>

              <div className="flex row-auto items-start justify-between mt-4">
                <Buttons menu icontype="dinas" text="Dinas" onClick={gotouploaddinas} />
                <Link to="/riwayat-dinas">
                  <Buttons menu icontype="profile" text="Riwayat Dinas" />
                </Link>

                {/* <Buttons menu icontype="riwayat" text="Riwayat Presensi" /> */}
                <Link to="/riwayat-izin">
                  <Buttons menu icontype="historyizin" text="Riwayat Izin" />
                </Link>
                <Buttons menu icontype="help" text="Bantuan" />
              </div>
            </div>

            <div className="mt-4">
              <h5 className="text-left text-gray-500 font-extrabold tracking-tight">
                Aktivitas
              </h5>
            </div>

            {isLoading ? (
              <div
                className="p-4 bg-white mt-4 shadow-md"
                style={{ borderRadius: 12, borderColor: colors.blue }}
              >
                <ShimmerTitle line={1} gap={10} variant="primary" />

                <ShimmerTitle line={1} gap={10} variant="primary" />

                <ShimmerTitle line={1} gap={10} variant="primary" />
              </div>
            ) : aktivitasList.length > 0 ? (
              aktivitasList.map((item) => {
                const [hours, minutes] = item.jam.split(":");
                const [year, month, day] = item.tgl.split("-");
                const formattedDate = `${day}-${month}-${year}`;

                return (
                  <div
                    className="p-4 mt-4 mb-4 bg-white shadow-md"
                    style={{ borderRadius: 12 }}
                  >
                    <div className="flex row-auto items-center justify-between">
                      <div className="flex row-auto items-center justify-between">
                        <div
                          style={{ backgroundColor: colors.green }}
                          className="rounded-full p-2"
                        >
                          <img
                            src={
                              item.jenis_kehadiran === "MASUK"
                                ? IconPresensiMasukPutih
                                : IconPresensiPulangPutih
                            }
                            alt="Icon presensi"
                            className="w-4"
                          />
                        </div>

                        <div className="ml-2">
                          <div className="text-sm font-bold">
                            Presensi{" "}
                            {item.jenis_kehadiran === "MASUK"
                              ? "Masuk"
                              : "Pulang"}
                          </div>
                          <div className="text-xs font-light">{`${formattedDate}`}</div>
                        </div>
                      </div>

                      <div className="flex row-auto items-center justify-between">
                        <div>
                          <div className="text-sm font-bold">{`${hours}:${minutes}`}</div>
                          <div className="text-xs font-light text-right">
                            {item.status_kehadiran}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div
                className="p-4 mt-4 mb-4 bg-white shadow-md text-sm text-center"
                style={{ borderRadius: 12 }}
              >
                Tidak ada aktivitas
              </div>
            )}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Home;

import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Helmet } from "react-helmet";

import { Header, Lists } from "../../components";
import { useAuth } from "../../context/AuthContext";
import { riwayatIzin } from "../../helpers/ApiPresensi";
import { decryptLocalStorage } from "../../helpers/SecureStorage";
import { colors } from "../../utils";

const RiwayatIzin = () => {
    const { logout } = useAuth();
    const [riwayat, setriwayat] = useState([]);

    const token = decryptLocalStorage(process.env.REACT_APP_LOCAL_STORAGE_TOKEN);

    const getRiwayat = async () => {
        try {
            const response = await fetch(riwayatIzin, {
                method: "GET",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                }
            });

            if (!response.ok) {
                logout();
                // throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            // console.log(body);

            setriwayat(data.data);
        } catch (error) {
            // console.error("Error during login:", error);
        }
    };

    useEffect(() => {
        getRiwayat();
    }, []);

    return (
        <div className="flex flex-col min-h-screen overflow-x-hidden">
            <Helmet>
                <title>Riwayat Izin</title>
            </Helmet>

            <div className="flex-grow" style={{ backgroundColor: colors.whitegrey }}>
                <div
                    className="w-full py-8 items-start"
                    style={{
                        height: 130,
                        backgroundColor: colors.blue,
                    }}
                ></div>

                <div className="lg:mx-8" style={{ marginTop: -120 }}>
                    <div className="p-4" style={{ borderRadius: 12 }}>
                        <Header to="/home" title="Riwayat Izin" />
                        {riwayat ? (
                            riwayat?.map((item, index) => (
                                <Lists
                                    riwayatizin
                                    key={index}
                                    text={item.nama_jenisizin}
                                    text2={item.tgl_pengajuanizin}
                                    text3={item.ket_statuspengajuan}
                                />
                            ))
                        ) : (
                            <div className="flex justify-center items-center h-full">
                                <p className="text-black text-base mt-4">
                                    Data tidak ditemukan
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RiwayatIzin;

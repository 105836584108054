import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import DatePicker from "react-datepicker";
import { id } from "date-fns/locale";
import { format } from "date-fns";

import { Header } from "../../components";
import { colors } from "../../utils";
import { decryptLocalStorage } from "../../helpers/SecureStorage";
import { useAuth } from "../../context/AuthContext";
import { rekapPresensiUrl } from "../../helpers/ApiPresensi";

const RekapPresensi = () => {
  const { logout } = useAuth();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [rekapList, setRekapList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const token = decryptLocalStorage(process.env.REACT_APP_LOCAL_STORAGE_TOKEN);

  const getRekapPresensi = async (periode_awal, periode_akhir) => {
    const periodeAwal = format(periode_awal, "yyyy-MM");
    const periodeAkhir = format(periode_akhir, "yyyy-MM");

    const body = {
      periode_awal: periodeAwal,
      periode_akhir: periodeAkhir,
    };

    try {
      setIsLoading(true);

      const response = await fetch(rekapPresensiUrl, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        logout();
      }

      const data = await response.json();

      if (data.data) {
        setRekapList(data.data);
      } else {
        setRekapList([]);
      }
    } catch (error) {
      // console.error("Error during login:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onFilter = (date, type) => {
    if (type === "awal") {
      setStartDate(date);
      getRekapPresensi(date, endDate);
    } else {
      setEndDate(date);
      getRekapPresensi(startDate, date);
    }
  };

  useEffect(() => {
    getRekapPresensi(startDate, endDate);
  }, []);

  return (
    <div className="flex flex-col min-h-screen overflow-x-hidden">
      <Helmet>
        <title>Rekap Presensi</title>
      </Helmet>

      <div className="flex-grow" style={{ backgroundColor: colors.whitegrey }}>
        <div
          className="w-full py-8 items-start"
          style={{
            height: 130,
            backgroundColor: colors.blue,
          }}
        ></div>

        <div className="lg:mx-8" style={{ marginTop: -120 }}>
          <div className="p-4" style={{ borderRadius: 12 }}>
            <Header to="/home" title="Rekap Presensi" />
            <div className="flex flex-col lg:flex-row items-center sm:justify-center lg:justify-between p-4 mt-4 rounded-md bg-white">
              <div>
                <label
                  htmlFor="datepicker"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Periode Awal
                </label>

                <DatePicker
                  className="datepicker-custom w-full px-4 py-2 border-2 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  selected={startDate}
                  onChange={(date) => onFilter(date, "awal")}
                  dateFormat="MM/yyyy"
                  locale={id}
                  showMonthYearPicker
                  showFullMonthYearPicker
                />
              </div>

              <div className="text-black text-md">s.d</div>

              <div>
                <label
                  htmlFor="datepicker"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Periode Akhir
                </label>

                <DatePicker
                  className="datepicker-custom w-full px-4 py-2 border-2 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  selected={endDate}
                  onChange={(date) => onFilter(date, "akhir")}
                  dateFormat="MM/yyyy"
                  locale={id}
                  showMonthYearPicker
                  showFullMonthYearPicker
                />
              </div>
            </div>

            {isLoading ? (
              <div className="flex justify-center flex-col items-center h-full bg-white mt-5 px-4 py-2">
                <div className="w-full flex row-auto items-center justify-center">
                  <p className="text-sm">Loading</p>
                </div>
              </div>
            ) : rekapList.length > 0 ? (
              rekapList.map((item, index) => <Rekap key={index} item={item} />)
            ) : (
              <div className="flex justify-center flex-col items-center h-full bg-white mt-5 px-4 py-2">
                <div className="w-full flex row-auto items-center justify-center">
                  <p className="text-sm">Rekap presensi tidak ditemukan</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const Rekap = ({ item }) => {
  return (
    <div className="flex justify-center flex-col items-center h-full bg-white mt-5 px-4 py-2">
      <div className="w-full flex row-auto items-center justify-between font-bold">
        <p className="text-sm">PERIODE</p>
        <p
          className="text-xs px-2 py-1 rounded-md font-bold"
          style={{ backgroundColor: colors.blue2, color: colors.blue }}
        >
          {`${item.nama_bulan} ${item.tahun}`}
        </p>
      </div>

      <div className="w-full flex row-auto items-start justify-between mt-5">
        <div className="flex flex-col items-center">
          <p className="text-sm">Hadir</p>
          <p className="text-sm">{item.jumlah_hadir}</p>
        </div>

        <div className="flex flex-col items-center">
          <p className="text-sm">Telat</p>
          <p className="text-sm">{item.jumlah_telat}</p>
        </div>

        <div className="flex flex-col items-center">
          <p className="text-sm">Tepat</p>
          <p className="text-sm">{item.jumlah_tepat}</p>
        </div>

        <div className="flex flex-col items-center">
          <p className="text-sm">Izin</p>
          <p className="text-sm">{item.jumlah_izin}</p>
        </div>
      </div>

      <div className="w-full flex row-auto items-start justify-between mt-3">
        <div className="flex flex-col items-center">
          <p className="text-sm">Cuti</p>
          <p className="text-sm">{item.jumlah_cuti}</p>
        </div>

        <div className="flex flex-col items-center">
          <p className="text-sm">Dinas</p>
          <p className="text-sm">{item.jumlah_dl}</p>
        </div>

        <div className="flex flex-col items-center">
          <p className="text-sm">Alpa</p>
          <p className="text-sm">{item.jumlah_alpa}</p>
        </div>

        <div className="flex flex-col items-center text-white">
          <p className="text-sm">Izin</p>
          <p className="text-sm">0</p>
        </div>
      </div>
    </div>
  );
};

export default RekapPresensi;

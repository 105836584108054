import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from 'react-router-dom';
import { Header } from "../../components";
import { editBiodata } from "../../helpers/ApiPresensi";
import { decryptLocalStorage } from "../../helpers/SecureStorage";
import { colors } from "../../utils";
import Swal from "sweetalert2";

const EditProfile = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const { foto, nama, alamat, email, no_hp } = location.state || {}
    const [emailuser, setemailuser] = useState(email)
    const [nohpuser, setnohpuser] = useState(no_hp)
    const [alamatuser, setalamatuser] = useState(alamat)
    const token = decryptLocalStorage(process.env.REACT_APP_LOCAL_STORAGE_TOKEN);

    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
        },
    });

    const handleSubmit = async (event) => {
        event.preventDefault();
        const body = {
            nama: nama,
            email: emailuser,
            no_hp: nohpuser,
            alamat: alamatuser
        };

        try {
            const response = await fetch(editBiodata, {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(body),
            });

            if (!response.ok) {
                const errorData = await response.json();
                Toast.fire({
                    icon: "error",
                    title: JSON.stringify(errorData.errors).replace(/["{}]/g, '').replace(/:/, ': '),
                });
                throw new Error(errorData.errors || 'Something went wrong');
            }
            Toast.fire({
                icon: "success",
                title: `Edit profil berhasil`,
            });
            navigate("/profile");

        } catch (error) {
            setTimeout(() => {
                Toast.fire({
                    icon: "error",
                    title: "Edit profil gagal",
                });
            }, 3000);
        }
    }

    return (
        <div className="flex flex-col min-h-screen overflow-x-hidden">
            <Helmet>
                <title>Edit Profil</title>
            </Helmet>
            <div className="flex-grow" style={{ backgroundColor: colors.whitegrey }}>
                <div
                    className="w-full p-8 items-start"
                    style={{
                        backgroundColor: colors.blue,
                    }}
                >
                    <Header to="/profile" title="Edit Profil" />
                </div>

                <div className="lg:mx-8" >
                    <div className="px-4" style={{ borderRadius: 12 }}>
                        <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-sm">
                            <form className="space-y-6" onSubmit={handleSubmit}>
                                <div>
                                    <label
                                        for="email"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                        Email
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            value={emailuser}
                                            onChange={(e) => setemailuser(e.target.value)}
                                            id="email"
                                            name="email"
                                            type="text"
                                            placeholder="Email"
                                            autocomplete="text"
                                            className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>

                                <div>
                                    <label
                                        for="nohp"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                        No Hp
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            value={nohpuser}
                                            onChange={(e) => setnohpuser(e.target.value)}
                                            id="nohp"
                                            name="nohp"
                                            type="text"
                                            placeholder="No Hp"
                                            autocomplete="text"
                                            className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>

                                <div>
                                    <label
                                        for="alamat"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                        Alamat
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            value={alamatuser}
                                            onChange={(e) => setalamatuser(e.target.value)}
                                            id="alamat"
                                            name="alamat"
                                            type="text"
                                            placeholder="Alamat"
                                            autocomplete="text"
                                            className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>


                                <div className="mt-8">
                                    <button
                                        type="submit"
                                        className="flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        style={{ backgroundColor: colors.blue, color: colors.white }}
                                    >
                                        Simpan
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditProfile;

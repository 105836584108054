export const baseUrl = "https://api-presensi.ponpeszamzam.my.id";
export const loginUrl = `${baseUrl}/auth/login`;
export const dashboardUrl = `${baseUrl}/dashboard`;
export const biodataUrl = `${baseUrl}/biodata`;
export const jadwalUrl = `${baseUrl}/jadwal`;
export const presensiUrl = `${baseUrl}/presensi`;
export const riwayatIzin = `${baseUrl}/izin`;
export const riwayatDinas = `${baseUrl}/dinas`;
export const editBiodata = `${baseUrl}/biodata/edit`;
export const editPassword = `${baseUrl}/auth/change-password`;
export const getStatusIzin = `${baseUrl}/dashboard/jenisizin`;
export const createIzin = `${baseUrl}/izin/create`;
export const createDinas = `${baseUrl}/dinas/create`;
export const riwayatPresensiUrl = `${baseUrl}/laporan/presensi`;
export const rekapPresensiUrl = `${baseUrl}/rekap/presensi`;

import React, { useState } from "react";

const DropdownShift = ({ options, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options[0].id_presensi);
  const [selectedLabel, setSelectedLabel] = useState(options[0].label);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    const selected = options.find(
      (item) => item.id_presensi === option.id_presensi
    );
    setSelectedOption(selected.id_presensi);
    setSelectedLabel(selected.label);
    onChange(option.id_presensi);

    setIsOpen(false);
  };

  const getSelected = (option) => {
    return option.id_presensi;
  };

  return (
    <div className="w-full relative inline-block text-left">
      <div>
        <button
          type="button"
          className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
          onClick={toggleDropdown}
        >
          {selectedLabel}
          <svg
            className="ml-2 -mr-1 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>

      {isOpen && (
        <div
          className="origin-top-right absolute right-0 mt-3 w-full z-10 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          <div className="py-1" role="none">
            {options.map((option, index) => (
              <button
                key={index}
                className={`block w-full text-left px-4 py-2 text-sm ${
                  selectedOption === option
                    ? "text-gray-900 bg-gray-100"
                    : "text-gray-700 hover:bg-gray-100"
                }`}
                role="option"
                aria-selected={selectedOption === getSelected(option)}
                onClick={() => handleOptionClick(option)}
              >
                {option.label}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DropdownShift;

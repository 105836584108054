import React from "react";
import { Link } from "react-router-dom";

import { colors } from "../../../utils/";

const Header = ({ to, title }) => {
  return (
    <div className="flex row-auto items-center justify-between">
      <Link to={to}>
        <button
          className="text-left font-semibold tracking-tight text-white text-sm"
          style={{
            backgroundColor: "transparent",
            border: "none",
            cursor: "pointer",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="w-5 h-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M22 12H5"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 19l-7-7 7-7"
            />
          </svg>
        </button>
      </Link>

      <h5 className="font-semibold text-white text-md">{title}</h5>

      <h5
        className="text-left font-semibold tracking-tight text-sm"
        style={{ color: colors.blue }}
      >
        None
      </h5>
    </div>
  );
};

export default Header;

import React from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  Navigate,
} from "react-router-dom";

import {
  Home,
  Login,
  Presensi,
  Jadwal,
  Profile,
  RiwayatPresensi,
  EditProfile,
  RekapPresensi,
  RiwayatIzin,
  RiwayatDinas,
  EditPassword,
  Izin,
  UploadDinas,
} from "./pages";
import { AuthProvider, useAuth } from "./context/AuthContext";

const ProtectedRoute = ({ element }) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? element : <Navigate to="/" />;
};

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route exact path="/" element={<Login />} />

          <Route path="/home" element={<ProtectedRoute element={<Home />} />} />

          <Route
            path="/presensi"
            element={<ProtectedRoute element={<Presensi />} />}
          />

          <Route
            path="/jadwal"
            element={<ProtectedRoute element={<Jadwal />} />}
          />

          <Route
            path="/profile"
            element={<ProtectedRoute element={<Profile />} />}
          />

          <Route
            path="/riwayat-presensi"
            element={<ProtectedRoute element={<RiwayatPresensi />} />}
          />

          <Route
            path="/riwayat-izin"
            element={<ProtectedRoute element={<RiwayatIzin />} />}
          />

          <Route
            path="/riwayat-dinas"
            element={<ProtectedRoute element={<RiwayatDinas />} />}
          />

          <Route
            path="/edit-profile"
            element={<ProtectedRoute element={<EditProfile />} />}
          />

          <Route
            path="/edit-password"
            element={<ProtectedRoute element={<EditPassword />} />}
          />

          <Route
            path="/rekap-presensi"
            element={<ProtectedRoute element={<RekapPresensi />} />}
          />

          <Route
            path="/izin"
            element={<ProtectedRoute element={<Izin />} />}
          />

          <Route
            path="/upload-dinas"
            element={<ProtectedRoute element={<UploadDinas />} />}
          />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;

import React from "react";
import {
  IconDinas,
  IconHelp,
  IconHistoryIzin,
  IconIzin,
  IconPresensi,
  IconProfile,
  IconReporting,
  IconRiwayat,
} from "../../../assets";

const Button = ({ menu, icontype, text, onClick }) => {
  return (
    <>
      {menu && (
        <button
          className="w-8 items-center flex flex-col"
          style={{ alignItems: "center" }}
          onClick={onClick}
        >
          {icontype === "presensi" && (
            <img src={IconPresensi} alt="" className="w-8" />
          )}
          {icontype === "izin" && <img src={IconIzin} alt="" className="w-8" />}
          {icontype === "dinas" && (
            <img src={IconDinas} alt="" className="w-8" />
          )}
          {icontype === "riwayat" && (
            <img src={IconRiwayat} alt="" className="w-8" />
          )}
          {icontype === "historyizin" && (
            <img src={IconHistoryIzin} alt="" className="w-8" />
          )}
          {icontype === "reporting" && (
            <img src={IconReporting} alt="" className="w-8" />
          )}
          {icontype === "profile" && (
            <img src={IconProfile} alt="" className="w-8" />
          )}
          {icontype === "help" && <img src={IconHelp} alt="" className="w-8" />}
          <h5 className="mt-2 text-center text-gray-500 font-semibold text-xs">
            {text}
          </h5>
        </button>
      )}
    </>
  );
};

export default Button;

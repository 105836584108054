import CryptoJS from "crypto-js";

const secretKey = process.env.REACT_APP_LOCAL_STORAGE_TOKEN;

export const hashKey = (key) => {
  return CryptoJS.HmacSHA256(key, secretKey).toString();
};

export const encryptLocalStorage = (name, data) => {
  const ecrypted = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    secretKey
  ).toString();
  const hashName = hashKey(name);

  localStorage.setItem(hashName, ecrypted);
};

export const decryptLocalStorage = (name) => {
  const storedEncryptedData = localStorage.getItem(hashKey(name));

  if (storedEncryptedData) {
    const bytes = CryptoJS.AES.decrypt(storedEncryptedData, secretKey);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    return decryptedData;
  }
};

export const removeLocalStorageItem = (name) => {
  localStorage.removeItem(hashKey(name));
};

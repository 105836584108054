import React, { createContext, useContext, useEffect, useState } from "react";
import { browserName } from "react-device-detect";

import {
  decryptLocalStorage,
  encryptLocalStorage,
  removeLocalStorageItem,
} from "../helpers/SecureStorage";
import { loginUrl } from "../helpers/ApiPresensi";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // const apiKey = localStorage.getItem("api_key");

    // if (apiKey) {
    //   setIsAuthenticated(true);
    // }

    const token = decryptLocalStorage(
      process.env.REACT_APP_LOCAL_STORAGE_TOKEN
    );

    if (token) {
      setIsAuthenticated(true);
    }
  }, []);

  const login = async (username, password, device_id) => {
    // const url = "https://api-presensi.ponpeszamzam.my.id/auth/login";

    const ip_address = "112.78.156.237";

    const body = {
      username: username,
      password: password,
      device_id: browserName,
      ip_address: ip_address,
      app_version: "1.7",
      app_name: "presensipegawaiweb",
      app_package: "presensipegawaiweb",
    };

    try {
      const response = await fetch(loginUrl, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      // console.log(response);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      setIsAuthenticated(true);

      encryptLocalStorage(
        process.env.REACT_APP_LOCAL_STORAGE_TOKEN,
        data.access_token
      );

      // localStorage.setItem("api_key", data.access_token);
      // localStorage.setItem("username", username);
      // localStorage.setItem("jenispegawai", data.pegawai.jenis_pegawai);
      // localStorage.setItem("shift", JSON.stringify(data.kehadiran.shift));
      // localStorage.setItem("lokasiList", JSON.stringify(data.kehadiran.lokasi));
      // localStorage.setItem("shiftList", JSON.stringify(data.kehadiran.shift));
      // localStorage.setItem("nama", data.pegawai.nama_pegawai);
    } catch (error) {
      // console.error("Error during login:", error);
      setIsAuthenticated(false);
      throw error;
    }
  };

  const logout = () => {
    removeLocalStorageItem(process.env.REACT_APP_LOCAL_STORAGE_TOKEN);

    // localStorage.removeItem("api_key");
    // localStorage.removeItem("username");
    // localStorage.removeItem("lokasiList");
    // localStorage.removeItem("shiftList");
    // localStorage.removeItem("shift");
    // localStorage.removeItem("nama");
    // localStorage.removeItem("jenispegawai");
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

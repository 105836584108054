import moment from "moment";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
import { Header } from "../../components";
import { createDinas } from "../../helpers/ApiPresensi";
import { decryptLocalStorage } from "../../helpers/SecureStorage";
import { colors } from "../../utils";

const UploadDinas = () => {
    const navigate = useNavigate()
    const data = useLocation()
    const shiftpresensi = data.state.shift
    const [shift, setshift] = useState([])
    const [keterangan, setketerangan] = useState('')
    const defaultOption = shift[0]
    const [tgl, settgl] = useState(new Date())
    const [file, setFile] = useState()
    const [shift_id, setshift_id] = useState('')
    const token = decryptLocalStorage(process.env.REACT_APP_LOCAL_STORAGE_TOKEN)

    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
        },
    });

    const handleSubmit = async (event) => {
        event.preventDefault();
        const body = new FormData()
        body.append('presensiId', shift_id == '' ? shift[0].id : shift_id)
        body.append('tanggal', moment(tgl).format('YYYY-MM-DD'))
        body.append('keterangan', keterangan)
        body.append('fileBukti', file)

        try {
            const response = await fetch(createDinas, {
                method: "POST",
                mode: "cors",
                headers: {
                    // "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${token}`
                },
                body: body,
            });

            if (!response.ok) {
                const errorData = await response.json();
                Toast.fire({
                    icon: "error",
                    title: JSON.stringify(errorData.message).replace(/["{}]/g, '').replace(/:/, ': ')
                });
                throw new Error(errorData.errors || 'Something went wrong');
            }
            Toast.fire({
                icon: "success",
                title: `Dinas berhasil`,
            });
            navigate("/home");

        } catch (error) {
            Toast.fire({
                icon: "error",
                title: "Dinas gagal",
            });
        }
    }

    function handleChange(event) {
        setFile(event.target.files[0])
    }

    const getshift = async () => {
        if (shiftpresensi) {
            const shiftData = shiftpresensi.map(item => ({
                id: item.id_presensi,
                label: item.label,
                value: item.label
            }))
            setshift(shiftData)
        } else {
            setshift([])
        }
    }

    const onselectstatus = (data) => {
        const objek = shift.find(item => item.value === data.value)
        setshift_id(objek.id)
    }

    useEffect(() => {
        getshift()
    }, [])

    return (
        <div className="flex flex-col min-h-screen overflow-x-hidden">
            <Helmet>
                <title>Upload Dinas</title>
            </Helmet>
            <div className="flex-grow" style={{ backgroundColor: colors.whitegrey }}>
                <div
                    className="w-full p-8 items-start"
                    style={{
                        backgroundColor: colors.blue,
                    }}
                >
                    <Header to="/home" title="Upload Dinas" />
                </div>

                <div className="lg:mx-8" >
                    <div className="px-4" style={{ borderRadius: 12 }}>
                        <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-sm">
                            <form className="space-y-6 bg-white p-4 rounded-md" onSubmit={handleSubmit}>
                                <div>
                                    <label
                                        for="shift"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                        Shift
                                    </label>
                                    <Dropdown options={shift} onChange={(data) => onselectstatus(data)} value={defaultOption} placeholder="Select an option" />
                                </div>

                                <div>
                                    <label
                                        htmlFor="datepicker"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                        Tanggal
                                    </label>

                                    <DatePicker
                                        className="datepicker-custom w-full px-4 py-2 border-2 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        selected={tgl}
                                        onChange={(date) => settgl(date)}
                                        dateFormat="dd/MM/yyyy"
                                    />
                                </div>

                                <div>
                                    <label
                                        for="keterangan"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                        Keterangan
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            value={keterangan}
                                            onChange={(e) => setketerangan(e.target.value)}
                                            id="keterangan"
                                            name="keterangan"
                                            type="text"
                                            placeholder="Keterangan"
                                            autocomplete="text"
                                            className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label
                                        for="bukti"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                        Bukti
                                    </label>
                                    <input type="file" onChange={handleChange} />
                                </div>
                                <div className="mt-8">
                                    <button
                                        type="submit"
                                        className="flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        style={{ backgroundColor: colors.blue, color: colors.white }}
                                    >
                                        Simpan
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UploadDinas;
import React from "react";
import { Link, useLocation } from "react-router-dom";

import {
  IconHomeActive,
  IconHomeInactive,
  IconProfileActive,
  IconProfileInactive,
} from "../../../assets";

const FooterButton = ({ to, icon, label }) => {
  const location = useLocation();
  const pathname = location.pathname;

  const getIcon = () => {
    if (icon === "home" && pathname === "/home") {
      return IconHomeActive;
    } else if (icon === "profile" && pathname === "/profile") {
      return IconProfileActive;
    } else if (icon === "home") {
      return IconHomeInactive;
    } else if (icon === "profile") {
      return IconProfileInactive;
    }
  };

  return (
    <Link to={to}>
      <div className="flex flex-col items-center rounded-lg px-6 py-2 hover:bg-gray-100">
        <img src={getIcon()} alt="Button footer" className="w-8" />
        <div className="text-sm mt-2 text-gray-700">{label}</div>
      </div>
    </Link>
  );
};

export default FooterButton;

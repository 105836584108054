import FooterButton from "../FooterButton";

const Footer = () => {
  return (
    <div className="w-full p-3 mt-4 sticky bottom-0 z-50 shadow-lg bg-white">
      <div className="flex items-center justify-center h-full space-x-8">
        <FooterButton to="/home" icon="home" label="Home" />

        <FooterButton to="/profile" icon="profile" label="Profil" />
      </div>
    </div>
  );
};

export default Footer;

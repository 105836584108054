import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import { ImageUser } from "../../assets";
import { Footer } from "../../components";
import { useAuth } from "../../context/AuthContext";
import { biodataUrl } from "../../helpers/ApiPresensi";
import { decryptLocalStorage } from "../../helpers/SecureStorage";
import { colors } from "../../utils";

const Profile = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [biodata, setbiodata] = useState([]);

  const token = decryptLocalStorage(process.env.REACT_APP_LOCAL_STORAGE_TOKEN);

  const handleLogout = () => {
    Swal.fire({
      title: "Apakah Anda ingin keluar dari aplikasi?",
      showCancelButton: true,
      confirmButtonText: "Ya",
      cancelButtonText: "Tidak",
    }).then((result) => {
      if (result.isConfirmed) {
        logout();
      } else if (result.isDenied) {
      }
    });
  };

  const gotoeditprofile = () => {
    const params = {
      foto: biodata?.foto,
      nama: biodata?.nama_pegawai,
      alamat: biodata?.alamat,
      email: biodata?.email,
      no_hp: biodata?.no_hp
    }
    navigate("/edit-profile", { state: params });
  };

  const gotoeditpassword = () => {
    navigate("/edit-password");
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });

  const getbio = async () => {
    try {
      // const url = "https://api-presensi.ponpeszamzam.my.id/biodata";

      const response = await fetch(biodataUrl, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status !== 200) {
        const data = await response.json();

        Toast.fire({
          icon: "error",
          title: data.message,
        });
        return;
      }

      const data = await response.json();
      setbiodata(data.data);
    } catch (error) {
      // console.error("Error during login:", error);
    } finally {
      // setIsLoading(false);
    }
  };

  useEffect(() => {
    getbio();
  }, []);

  return (
    <div className="flex flex-col min-h-screen overflow-x-hidden">
      <Helmet>
        <title>Profile</title>
      </Helmet>

      <div className="flex-grow" style={{ backgroundColor: colors.whitegrey }}>
        <div
          className="w-full py-8 items-start"
          style={{
            height: 130,
            backgroundColor: colors.blue,
          }}
        ></div>

        <div
          className="flex flex-col items-center justify-center"
          style={{ marginTop: -80 }}
        >
          <img
            src={biodata?.foto === false ? ImageUser : biodata?.foto}
            className="w-36 h-36 border-8 bg-gray-300 border-white rounded-full"
            alt="User"
          />
          <h1 class="mt-4 text-xl font-bold">{biodata?.nama_pegawai}</h1>
          <p class="mt-2 text-gray-600">{biodata?.jenis_pegawai}</p>
        </div>

        <div className="w-full lg:w-2/3 mx-auto flex flex-col items-center justify-center mt-8 lg:px-0 px-8">
          <button
            onClick={gotoeditprofile}
            className="px-4 py-2 bg-white hover:bg-gray-100 duration-200 w-full rounded-xl border-2 border-gray-400 hover:border-gray-500 font-semibold"
          >
            Edit Profil
          </button>
        </div>

        <div className="w-full lg:w-2/3 mx-auto flex flex-col items-center justify-center mt-8 lg:px-0 px-8">
          <button
            onClick={gotoeditpassword}
            className="px-4 py-2 bg-white hover:bg-gray-100 duration-200 w-full rounded-xl border-2 border-gray-400 hover:border-gray-500 font-semibold"
          >
            Edit Password
          </button>
        </div>

        <div className="w-full lg:w-2/3 mx-auto flex flex-col items-center justify-center mt-8 lg:px-0 px-8">
          <button
            onClick={handleLogout}
            className="px-4 py-2 bg-white hover:bg-gray-100 duration-200 w-full rounded-xl border-2 border-gray-400 hover:border-gray-500 font-semibold"
          >
            Logout
          </button>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Profile;

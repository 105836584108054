const Colors = {
  blue: "#1277C1",
  blue2: "#D4FBFF",
  blue3: "#B9EEFB",
  blue4: "#A2E3F8",
  blue5: "#206EA2",
  black: "#000000",
  blacktranparent: "rgba(0, 0, 0, 0.5)",
  grey: "#AAAEB4",
  greydark: "#667085",
  white: "#FFFFFF",
  whitetransparent: "rgba(255, 255, 255, 0.5)",
  whitegrey: "#F2F4F7",
  whitegreen: "#F3F9F2",
  whiteblue: "#E7F1F9",
  green: "#12C14E",
  greendark: "#146608",
  greenlight: "#C7FFDA",
  orange: "#FFB703",
  yellowlight: "#FFE5A3",
  redlight: "#FFA490",
  red: "#FF2147",
};

export const colors = {
  blue: Colors.blue,
  blue2: Colors.blue2,
  blue3: Colors.blue3,
  blue4: Colors.blue4,
  blue5: Colors.blue5,
  black: Colors.black,
  blacktranparent: Colors.blacktranparent,
  grey: Colors.grey,
  greydark: Colors.greydark,
  white: Colors.white,
  whitetransparent: Colors.whitetransparent,
  whitegrey: Colors.whitegrey,
  whitegreen: Colors.whitegreen,
  whiteblue: Colors.whiteblue,
  green: Colors.green,
  greendark: Colors.greendark,
  greenlight: Colors.greenlight,
  orange: Colors.orange,
  yellowlight: Colors.yellowlight,
  redlight: Colors.redlight,
  red: Colors.red,
};

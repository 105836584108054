import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Helmet } from "react-helmet";
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
import { Header } from "../../components";
import { editPassword } from "../../helpers/ApiPresensi";
import { decryptLocalStorage } from "../../helpers/SecureStorage";
import { colors } from "../../utils";

const EditPassword = () => {
    const navigate = useNavigate()
    const [old_password, setold_password] = useState('')
    const [password, setpassword] = useState('')
    const [repeat_password, setrepeat_password] = useState('')
    const token = decryptLocalStorage(process.env.REACT_APP_LOCAL_STORAGE_TOKEN);

    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
        },
    });

    const handleSubmit = async (event) => {
        event.preventDefault();
        const body = {
            old_password: old_password,
            password: password,
            repeat_password: repeat_password
        };

        try {
            const response = await fetch(editPassword, {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(body),
            });

            if (!response.ok) {
                const errorData = await response.json();
                Toast.fire({
                    icon: "error",
                    title: JSON.stringify(errorData.errors).replace(/["{}]/g, '').replace(/:/, ': '),
                });
                throw new Error(errorData.errors || 'Something went wrong');
            }
            Toast.fire({
                icon: "success",
                title: `Edit password berhasil`,
            });
            navigate("/profile");

        } catch (error) {
            setTimeout(() => {
                Toast.fire({
                    icon: "error",
                    title: "Edit password gagal",
                });
            }, 3000);
        }
    }

    return (
        <div className="flex flex-col min-h-screen overflow-x-hidden">
            <Helmet>
                <title>Edit Password</title>
            </Helmet>
            <div className="flex-grow" style={{ backgroundColor: colors.whitegrey }}>
                <div
                    className="w-full p-8 items-start"
                    style={{
                        backgroundColor: colors.blue,
                    }}
                >
                    <Header to="/profile" title="Edit Password" />
                </div>

                <div className="lg:mx-8" >
                    <div className="px-4" style={{ borderRadius: 12 }}>
                        <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-sm">
                            <form className="space-y-6" onSubmit={handleSubmit}>
                                <div>
                                    <label
                                        for="old_password"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                        Password Lama
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            value={old_password}
                                            onChange={(e) => setold_password(e.target.value)}
                                            id="old_password"
                                            name="old_password"
                                            type="password"
                                            placeholder="Password Lama"
                                            autocomplete="text"
                                            className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>

                                <div>
                                    <label
                                        for="password"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                        Password Baru
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            value={password}
                                            onChange={(e) => setpassword(e.target.value)}
                                            id="password"
                                            name="password"
                                            type="password"
                                            placeholder="Password Baru"
                                            autocomplete="text"
                                            className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>

                                <div>
                                    <label
                                        for="alamat"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                        Konfirmasi Password
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            value={repeat_password}
                                            onChange={(e) => setrepeat_password(e.target.value)}
                                            id="repeat_password"
                                            name="repeat_password"
                                            type="password"
                                            placeholder="Konfirmasi Password"
                                            autocomplete="text"
                                            className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>


                                <div className="mt-8">
                                    <button
                                        type="submit"
                                        className="flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        style={{ backgroundColor: colors.blue, color: colors.white }}
                                    >
                                        Simpan
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditPassword;

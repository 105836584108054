import { colors } from "../../../utils/colors";
import { IconPresensiMasuk, IconPresensiPulang } from "../../../assets";

const ItemJadwal = ({ hari, tgl, jadwal_masuk, jadwal_pulang }) => {
  return (
    <div
      className="p-4 bg-white hover:bg-blue-100 duration-200 border-l-4 mt-4 shadow-md"
      style={{ borderRadius: 12, borderColor: colors.blue }}
    >
      <div className="flex row-auto items-center justify-between">
        <h5 className="text-left font-semibold tracking-tight text-sm">
          {hari}
        </h5>
        <h5
          className="rounded-full px-4 py-2 font-normal tracking-tight text-sm"
          style={{
            backgroundColor: colors.whitegrey,
          }}
        >
          {tgl}
        </h5>
      </div>

      <div className="flex row-auto items-center justify-between mt-2 space-x-4">
        <div className="flex w-full justify-start items-center rounded-md py-1.5 text-sm font-semibold leading-6 mr-6">
          <div
            className="rounded-full w-12 h-12 flex justify-center items-center"
            style={{ backgroundColor: colors.whitegrey }}
          >
            <img alt="candice" src={IconPresensiMasuk} className="w-6 h-6" />
          </div>

          <div className="ml-4">
            <div className="text-md font-bold">{jadwal_masuk}</div>
            <div
              className="text-xs font-normal"
              style={{
                color: colors.greydark,
              }}
            >
              Masuk
            </div>
          </div>
        </div>

        <div className="flex w-full justify-end items-center rounded-md py-1.5 text-sm font-semibold leading-6 mr-6">
          <div
            className="rounded-full w-12 h-12 flex justify-center items-center"
            style={{ backgroundColor: colors.whitegrey }}
          >
            <img alt="candice" src={IconPresensiPulang} className="w-6 h-6" />
          </div>

          <div className="ml-4">
            <div className="text-md font-bold">{jadwal_pulang}</div>
            <div
              className="text-xs font-normal"
              style={{
                color: colors.greydark,
              }}
            >
              Pulang
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ItemJadwal;

import moment from 'moment'
import React from 'react'

const Lists = ({ riwayatpresensi, riwayatizin, riwayatdinas, text, text2, text3, text4, text5, onClick }) => {
    return (
        <>
            {
                riwayatpresensi && <div className="bg-white p-4 rounded-md mt-4">
                    <div className="text-sm font-bold">Tanggal</div>
                    <div className="text-sm font-normal">{moment(text).format('DD-MM-yyyy')}</div>
                    <div className="text-sm font-bold mt-2">Masuk</div>
                    <div className="text-sm font-normal">{text2} - {text3}</div>
                    <div className="text-sm font-bold mt-2">Pulang</div>
                    <div className="text-sm font-normal">{text4} - {text5}</div>
                </div>
            }
            {
                riwayatizin && <div className="bg-white p-4 rounded-md mt-4">
                    <div className="text-sm font-bold">Jenis Izin</div>
                    <div className="text-sm font-normal">{text}</div>
                    <div className="text-sm font-bold mt-2">Tanggal</div>
                    <div className="text-sm font-normal">{moment(text2).format('DD-MM-yyyy')}</div>
                    <div className="text-sm font-bold mt-2">Status</div>
                    <div className="text-sm font-normal">{text3}</div>
                </div>
            }
            {
                riwayatdinas && <div className="bg-white p-4 rounded-md mt-4">
                    <div className="text-sm font-bold mt-2">Tanggal</div>
                    <div className="text-sm font-normal">{moment(text).format('DD-MM-yyyy')}</div>
                    <div className="text-sm font-bold mt-2">Keterangan</div>
                    <div className="text-sm font-normal">{text2}</div>
                    <div className="text-sm font-bold mt-2">Status</div>
                    <div className="text-sm font-normal">{text3}</div>
                    <div className="text-sm font-bold mt-2">Jumlah Hari</div>
                    <div className="text-sm font-normal">{text4} Hari</div>
                    <button onClick={onClick}>
                        <div className="text-sm font-bold mt-2">Lihat Bukti Dinas</div>
                    </button>
                </div>
            }
        </>
    )
}

export default Lists


import Swal from "sweetalert2";
import React, { useEffect, useState } from "react";
import momentTimezone from "moment-timezone";
import { Helmet } from "react-helmet";
import { SyncLoader } from "react-spinners";
import { useLocation } from "react-router-dom";

import { decryptLocalStorage } from "../../helpers/SecureStorage";
import { dashboardUrl, presensiUrl } from "../../helpers/ApiPresensi";
import { ShimmerTitle } from "react-shimmer-effects";
import { DropdownLokasi, DropdownShift, Header } from "../../components";
import { colors } from "../../utils";
import { useAuth } from "../../context/AuthContext";

const Presensi = () => {
  const data = useLocation();
  const tipe_presensi = data.state;
  const title =
    tipe_presensi === "masuk" ? "Presensi Masuk" : "Presensi Pulang";

  const token = decryptLocalStorage(process.env.REACT_APP_LOCAL_STORAGE_TOKEN);

  const now = new Date();
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const time = `${hours}:${minutes}`;

  const { logout } = useAuth();
  const [location, setLocation] = useState({ lat: null, long: null });
  const [isLoading, setIsLoading] = useState(false);
  const [shiftList, setShiftList] = useState([]);
  const [lokasiList, setLokasiList] = useState([]);
  const [jumlahShift, setJumlahShift] = useState(0);
  const [jumlahLokasi, setJumlahLokasi] = useState(0);
  const [distance, setDisatance] = useState(0);
  const [selectedLokasi, setSelectedLokasi] = useState(null);
  const [selectedShift, setSelectedShift] = useState(null);
  const [labelinfo, setLabelinfo] = useState("Presensi");

  const calculateDistance = (latitude1, longitude1, latitude2, longitude2) => {
    // Convert degrees to radians
    const toRadians = (degree) => degree * (Math.PI / 180);

    const lat1 = toRadians(latitude1);
    const lon1 = toRadians(longitude1);
    const lat2 = toRadians(latitude2);
    const lon2 = toRadians(longitude2);

    // Earth radius in meters
    const earthRadius = 6371000; // 6371 km

    // Haversine formula
    const dLat = lat2 - lat1;
    const dLon = lon2 - lon1;
    const a =
      Math.sin(dLat / 2) ** 2 +
      Math.cos(lat1) * Math.cos(lat2) * Math.sin(dLon / 2) ** 2;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = earthRadius * c;

    // const jarak = distance.toFixed(2);

    const jarak = distance;

    return jarak;
  };

  const formatNumber = (number) => {
    return number.toLocaleString("id-ID", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });

  const getDashboard = async () => {
    try {
      setIsLoading(true);

      const response = await fetch(dashboardUrl, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Network error");
      }

      const result = await response.json();

      if (result.data.shift) {
        setShiftList(result.data.shift);
        setSelectedShift(result.data.shift[0].id_presensi);

        setLabelinfo(
          `Waktu presensi ${tipe_presensi} adalah ${
            tipe_presensi === "masuk" ? "sebelum" : ""
          } jam ${
            tipe_presensi === "masuk"
              ? result.data.shift[0].masuk_batas_akhir
              : result.data.shift[0].pulang_batas_awal
          }`
        );

        setJumlahShift(result.data.shift.length);
      }

      if (result.data.lokasi) {
        setLokasiList(result.data.lokasi);

        setSelectedLokasi(result.data.lokasi[0].setting_id);

        setJumlahLokasi(result.data.lokasi.length);
      }
    } catch (error) {
      // console.log(error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const newLocation = {
            lat: position.coords.latitude,
            long: position.coords.longitude,
          };

          setLocation(newLocation);

          // console.log(newLocation);
        },
        (error) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Perangkat ini tidak memberikan izin lokasi",
            willClose: () => {
              window.open("/home", "_blank");
            },
          });
        }
      );
    } else {
      const errorMsg = "Perangkat ini tidak mendukung geolocation";
      Swal.fire({
        icon: "error",
        title: "Error",
        text: errorMsg,
        willClose: () => {
          window.open("/home", "_blank");
        },
      });
    }
  }, []);

  useEffect(() => {
    getDashboard();
  }, []);

  useEffect(() => {
    const lokasi = lokasiList.find(
      (item) => item.setting_id === selectedLokasi
    );

    if (lokasi) {
      const calDisatance = calculateDistance(
        location.lat,
        location.long,
        lokasi.latitude,
        lokasi.longitude
      );

      // console.log(calDisatance);

      setDisatance(calDisatance);
    }
  }, [lokasiList, selectedLokasi, location.lat, location.long]);

  useEffect(() => {
    const shift = shiftList.find((item) => item.id_presensi === selectedShift);

    if (shift) {
      setLabelinfo(
        `Waktu presensi ${tipe_presensi} adalah ${
          tipe_presensi === "masuk" ? "sebelum" : ""
        } jam ${
          tipe_presensi === "masuk"
            ? shift.masuk_batas_akhir
            : shift.pulang_batas_awal
        }`
      );
    }
  }, [selectedShift, shiftList, tipe_presensi]);

  // PRESENSI

  const presensikan = async () => {
    const lokasi = lokasiList.find(
      (item) => item.setting_id === selectedLokasi
    );
    const shift = shiftList.find((item) => item.id_presensi === selectedShift);

    const now = momentTimezone().tz("Asia/Jakarta");
    const format = "HH:mm:ss";

    const jam_sekarang = momentTimezone(now.format(format), format);
    let status_presensi = "TEPAT";

    if (tipe_presensi === "masuk") {
      const masuk_batas_akhir = momentTimezone(
        shift.masuk_batas_akhir,
        format
      ).tz("Asia/Jakarta");

      if (jam_sekarang.isAfter(masuk_batas_akhir)) {
        status_presensi = "TELAT";
      }
    }

    if (distance > lokasi.radius) {
      Toast.fire({
        icon: "error",
        title: "Kamu di luar radius",
      });
    } else {
      setIsLoading(true);

      try {
        const response = await fetch(presensiUrl, {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
          body: JSON.stringify({
            id_presensi: selectedShift,
            jenis_presensi: "REGULER",
            tipe_presensi: tipe_presensi,
            status_kehadiran: status_presensi,
            id_lokasi: `${selectedLokasi}`,
            latitude: `${location.lat}`,
            longitude: `${location.long}`,
            keterangan: "PRESENSI MELALUI WEBSITE",
          }),
        });

        if (response.status !== 200) {
          const data = await response.json();

          if (response.status === 401) {
            logout();
          }

          Toast.fire({
            icon: "error",
            title: data.message,
          });
          return;
        }

        await response.json();

        Toast.fire({
          icon: "success",
          title: `Presensi berhasil`,
        });
      } catch (error) {
        // console.error("Error during login:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div>
      {location.lat && location.long ? (
        <div className="flex flex-col min-h-screen overflow-x-hidden">
          <Helmet>
            <title>Jadwal Presensi</title>
          </Helmet>

          <div
            className="flex-grow"
            style={{ backgroundColor: colors.whitegrey }}
          >
            <div
              className="w-full py-8 items-start"
              style={{
                height: 130,
                backgroundColor: colors.blue,
              }}
            ></div>

            <div className="lg:mx-8" style={{ marginTop: -120 }}>
              <div className="p-4" style={{ borderRadius: 12 }}>
                <Header to="/home" title={title} />

                {isLoading ? (
                  <LoadingWidget />
                ) : (
                  <div
                    className="p-4 bg-white mt-4 shadow-md"
                    style={{ borderRadius: 12, borderColor: colors.blue }}
                  >
                    <h5
                      className="rounded-full px-4 py-2 font-thin text-xs text-center mb-4"
                      style={{ backgroundColor: colors.whitegrey }}
                    >
                      {labelinfo}
                    </h5>

                    <div className="flex row-auto items-center justify-between mb-4">
                      <h5 className="text-left font-semibold tracking-tight text-sm">
                        Waktu Presensi
                      </h5>
                      <h5 className="font-light tracking-tight text-sm">
                        {time}
                      </h5>
                    </div>

                    <div className="flex row-auto items-center justify-between mb-4">
                      <h5 className="text-left font-semibold tracking-tight text-sm">
                        Jarak
                      </h5>
                      <h5 className="font-light tracking-tight text-sm">
                        {formatNumber(distance)} meter{" "}
                      </h5>
                    </div>

                    <h5 className="text-left font-semibold tracking-tight text-sm mb-4">
                      Lokasi
                    </h5>

                    {jumlahLokasi > 0 ? (
                      <DropdownLokasi
                        options={lokasiList}
                        onChange={setSelectedLokasi}
                        type="lokasi"
                      />
                    ) : (
                      <p className="text-xs">Tidak ada lokasi</p>
                    )}

                    <h5 className="text-left font-semibold tracking-tight text-sm my-4">
                      Shift
                    </h5>

                    {jumlahShift > 0 ? (
                      <DropdownShift
                        options={shiftList}
                        onChange={setSelectedShift}
                      />
                    ) : (
                      <p className="text-xs">Tidak ada shift</p>
                    )}

                    {jumlahLokasi > 0 && jumlahShift > 0 ? (
                      <button
                        onClick={presensikan}
                        className="px-4 py-2 rounded-full w-full text-white mt-4"
                        style={{ backgroundColor: colors.blue }}
                      >
                        Presensi
                      </button>
                    ) : (
                      <div></div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <ShimmerLoading />
      )}
    </div>
  );
};

const LoadingWidget = () => {
  return (
    <div
      className="p-4 bg-white mt-4 shadow-md h-96 flex flex-col items-center justify-center"
      style={{ borderRadius: 12, borderColor: colors.blue }}
    >
      <SyncLoader size={8} speedMultiplier={0.6} color={colors.blue} />
      <div
        className="mt-4 tracking-widest text-md italic"
        style={{ color: colors.blue }}
      >
        Loading ...
      </div>
    </div>
  );
};

const ShimmerLoading = () => {
  return (
    <div className="flex flex-col min-h-screen overflow-x-hidden">
      <Helmet>
        <title>Jadwal Presensi</title>
      </Helmet>

      <div className="flex-grow" style={{ backgroundColor: colors.whitegrey }}>
        <div
          className="w-full py-8 items-start"
          style={{
            height: 130,
            backgroundColor: colors.blue,
          }}
        ></div>

        <div className="lg:mx-8" style={{ marginTop: -120 }}>
          <div className="p-4" style={{ borderRadius: 12 }}>
            <Header to="/home" title="Presensi Masuk" />

            <div
              className="p-4 bg-white mt-4 shadow-md"
              style={{ borderRadius: 12, borderColor: colors.blue }}
            >
              <ShimmerTitle line={1} gap={10} variant="primary" />

              <ShimmerTitle line={1} gap={10} variant="primary" />

              <ShimmerTitle line={1} gap={10} variant="primary" />

              <ShimmerTitle line={1} gap={10} variant="primary" />

              <ShimmerTitle line={1} gap={10} variant="primary" />

              <ShimmerTitle line={1} gap={10} variant="primary" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Presensi;
